<template>
  <div class="im">
    <wxChat
      :showShade="false"
      :contactNickname="PartnerName ? PartnerName : '司机名称'"
      :width="width"
    ></wxChat>
    <div class="insert">
      <div class="butinput">
        <form
          class="search-block"
          action="javascript:void 0"
        >
          <textarea
            rows="2"
            style="vertical-align: top; outline: none"
            v-model="content"
            @keyup.13="sendSingleMsg"
            ref="input1"
          ></textarea>
        </form>
        <div
          class="send"
          @click="sendSingleMsg"
        >
          <div class="sendtxt">发送</div>
        </div>
      </div>
      <div
        class="imbutsss"
        style="width: 100%; height: 5px"
      ></div>
    </div>
  </div>
</template>

<script>
import { getStore, setStore, removeStore } from "@/lib/storage.js";
import { mapState, mapMutations } from "vuex";
import { getFormat } from "../../lib/units";
import wxChat from "../../components/wxchat/wxChat";
import globalVal from "../../store/modules/global";
import { IMappkey, driverAppkey } from "@/config";
import { getOrderDetailByOrderId, newsetmsg, newgetmsg } from "@/service/";
import store from "../../store/store.js";
export default {
  name: "im",
  timer: null,
  provide() {
    return {
      superParams: this,
    };
  },
  data() {
    return {
      textnum: 0,
      im_Pmid: "",
      im_Umid: "",
      state: "",
      registerNum: 0,
      msglist: [],
      throttle_timer: null,
      orderId: "",
      Jim: {},
      scrollHeight: 0,
      PartnerName: "",
      form: {
        username: "",
        password: "",
      },
      content: "",
      target_username: "",
      width: window.screen.width,
    };
  },
  components: { wxChat },
  computed: {
    ...mapState({
      msglist: ({ globalVal }) => JSON.parse(getStore("msglist")),
      msgnums: ({ globalVal }) => JSON.parse(getStore("msgnums")),
    }),
  },
  filters: {
    format: function (val) {
      if (val) {
        return getFormat(val);
      }
    },
  },
  mounted() {
    let _this = this;
    console.log("im_mounted");

    // setInterval(() => {
    //   _this.textnum += 1;
    // }, 400);
    // console.log("window.location.href:", window.location.href);
  },
  methods: {
    ...mapMutations(["SET_MSG_LIST"]),
    initWidth() {
      var ua = navigator.userAgent;
      var ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
        isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
        isAndroid = ua.match(/(Android)\s+([\d.]+)/),
        isMobile = isIphone || isAndroid;
      //非移动端设置400px宽度，移动端是100%
      if (!isMobile) {
        this.width = 400;
      }
    },
    // 初始化
    init() {
      let _this = this;
      this.$chat
        .init()
        .then((res) => {
          console.log("初始化成功");
          this.islogin();
        })
        .catch((err) => {
          console.log("初始化失败");
        });
    },
    islogin() {
      if (this.$chat.isLogin()) {
        console.log("已登录");
        // this.register();
      } else {
        console.log("未登录");
        this.login();
      }
    },
    // 登录
    login() {
      let _userInfo = JSON.parse(getStore("userInfo"));
      let form = {
        username: "UM" + _userInfo.Tel,
        password: "123456",
      };
      console.log("login_form:", form);
      this.$chat
        .login(form)
        .then((res) => {
          console.log("登录成功", res);
          if (this.content) {
            this.sendSingleMsg();
          }
        })
        .catch((err) => {
          console.log("登录失败 err:", err);
          console.log("登录失败");
          if (this.registerNum < 4) {
            this.register();
          }
        });
    },
    // 退出登录
    loginout() {
      this.$chat.loginout();
    },
    // 注册
    register() {
      let _userInfo = JSON.parse(getStore("userInfo"));
      let form = {
        username: "UM" + _userInfo.Tel,
        password: "123456",
      };
      this.$chat.loginout();
      this.$chat
        .register(form)
        .then((res) => {
          this.registerNum++;
          console.log("注册成功", res);
          this.login();
        })
        .catch((err) => {
          console.log("注册失败", err);
        });
    },
    // 发送消息
    sendSingleMsg() {
      this.$refs.input1.blur();
      let partner = JSON.parse(getStore("partner"));
      if (!this.content) {
        this.$toast("请输入文字");
        return;
      } else {
        let _content = this.content;
        this.content = "";
        this.setmsg(_content);
      }
      //   this.setmsg();
    },

    async setmsg(content) {
      if (!content) {
        this.$toast("请输入文字");
        return;
      }
      let _parms = {
        Umid: Number(this.im_Umid),
        OrderId: getStore("orderId"),
        Content: content,
        Pmid: 0,
      };

      let res = await newsetmsg(_parms);
      //   this.getmsglist();
      //   if (res.code == 1) {
      //     clearInterval(this.timer);
      //     this.getmsglist();
      //   }
    },
    // 获取聊天记录
    getmsglist() {
      console.log("im_getmsglist");
      const _this = this;
      let selectid = 0,
        _orderId = getStore("orderId");
      newgetmsg(`${getStore("orderId")}`, `${selectid}`).then((res) => {
        console.log("newgetmsg:", res);
        if (res) {
          let arr = JSON.parse(res.data);
          arr.forEach(function (value) {
            value.CreateDate1 = _this.format(value.CreateDate);
            value.CreateDate2 = new Date(value.CreateDate).getTime();
          });
          let msgList = [];
          let _userInfo = JSON.parse(getStore("userInfo"));

          arr.forEach(function (value) {
            let obj = {
              OrderId: getStore("orderId"),
              msg_id: value.OrderId,
              you_name: value.Umid,
              me_username: _userInfo.Nickname,
              CreateDate: value.CreateDate,
              create_time: value.CreateDate,
              text: value.Content,
              direction: value.Pmid == 0 ? 2 : 1,
            };
            msgList.push(obj);
          });
          if (arr.length > Number(getStore("msgnums"))) {
            store.commit("SET_MSG_LIST", arr);
            _this.textnum += 1;
          }
          if (_this.state > 5) {
            // clearInterval(this.timer);
            removeStore("partner");
            removeStore("im_Pmid");
            removeStore("im_Umid");
            removeStore("msglist");
            _this.$router.go(-1);
          }

          //   _this.handleInterval();
        }
      });
    },
    compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      };
    },
    add0(m) {
      return m < 10 ? "0" + m : m;
    },
    format(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return (
        y +
        "-" +
        this.add0(m) +
        "-" +
        this.add0(d) +
        " " +
        this.add0(h) +
        ":" +
        this.add0(mm) +
        ":" +
        this.add0(s)
      );
    },

    handleInterval() {
      let _this = this;
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = null;
      if (_this.state > 5) {
        // clearInterval(this.timer);
        removeStore("partner");
        removeStore("im_Pmid");
        removeStore("im_Umid");
        removeStore("msglist");
        _this.$router.go(-1);
      } else {
        _this.timer = setInterval(() => {
          //   _this.getOrderDetail(this.orderId);
          _this.getmsglist();
        }, 1000);
      }
    },
    // 获取订单信息
    async getOrderDetail(orderId) {
      // let orderId = getStore("orderId");
      if (this.state > 5) {
        clearInterval(this.timer);
        this.timer = null;
      }
      let res = await getOrderDetailByOrderId(orderId);
      if (res.code == 1) {
        this.state = res.data.State;
        if (this.state > 5) {
          clearInterval(this.timer);
          this.timer = null;
          this.SET_MSG_LIST([]);
          this.$chat.loginout();
          this.$router.go(-1);
        }
      } else {
        clearInterval(this.timer);
        this.timer = null;
        this.$chat.loginout();
        this.SET_MSG_LIST([]);
        this.$router.go(-1);
      }
    },
  },
  created() {
    this.initWidth();

    // this.$chat.getJim();
    // this.init();
    if (getStore("partner")) {
      this.PartnerName = JSON.parse(getStore("partner")).AccountStr;
      this.orderId = getStore("orderId");
      console.log("orderId:", this.orderId);
    }
    this.im_Pmid = getStore("im_Pmid");
    this.im_Umid = getStore("im_Umid");
    this.getmsglist();
    this.handleInterval();
  },
  beforeDestroy() {
    console.log("im_beforeDestroy");
    clearInterval(this.timer);
    // store.commit("SET_MSGNUMS", 0);
    this.timer = null;
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style lang="less" scoped>
.im {
  font-size: 50px;
  .insert {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 200;
  }
  .butinput {
    width: 98%;
    padding: 1%;
    background: #bebebe;
    display: flex;
    min-height: 120px;
    .send {
      // width: 100px;
      padding: 20px 24px;

      .sendtxt {
        height: 80px;
        width: 150px;
        text-align: center;
        color: white;
        background: #00db00;
        padding: 10px;
        border-radius: 20px;
      }
    }
    textarea {
      flex: 1;
    }
  }
}
</style>

