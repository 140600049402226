// //微信聊天可视化组件
// //依赖scrollLoader组件, 依赖指令v-emotion（实现请查看main.js）

// //参数：
// // width               组件宽度，默认450
// // wrapBg              外层父元素背景颜色，默认#efefef
// // maxHeight           展示窗口最高高度, 默认900
// // contactAvatarUrl    好友头像url
// // ownerAvatarUrl      微信主人头像url
// // ownerNickname       微信主人昵称
// // getUpperData        （必需）当滚动到上方时加载数据的方法，返回值要为Promise对象，resolve的结构同data
// // getUnderData        （必需）当滚动到下方时加载数据的方法，返回值同上
// // data                （必需）传入初始化数据， 结构如下：
// [{
//     direction: 2, //为2表示微信主人发出的消息，1表示联系人
//     id: 1, //根据这个来排序消息
//     type: 1, //1为文本，2为图片
//     content: '你好!![呲牙]', //当type为1时这里是文本消息，当type2为2时这里要存放图片地址；后续会支持语音的显示
//     ctime: new Date().toLocaleString() //显示当前消息的发送时间
// },
// {
//     direction: 1,
//     id: 2,
//     type: 1,
//     content: '你也好。[害羞]',
//     ctime: new Date().toLocaleString()
// }]


<template>
  <div
    class="wxchat-container"
    :style="{ backgroundColor: wrapBg }"
  >
    <div
      class="window"
      id="window-view-container"
      :style="{ maxHeight: maxHeight + 'px', width: width + 'px' }"
    >
      <div class="title">
        <img
          @click="goBack"
          src="../../assets/fanhui@2x.png"
          alt="返回"
        />
        <p v-text="contactNickname"></p>
      </div>
      <!-- <HeaderBar
        :title="contactNickname"
        :showLeft="true"
      ></HeaderBar> -->

      <!-- data is empty -->
      <div
        class="loading"
        v-if="dataArray && dataArray.length == 0"
      >
        <div style="margin-top: 300px; text-align: center; font-size: 16px">
          <span>未查找到聊天记录</span>
        </div>
      </div>

      <!-- loading -->
      <div
        class="loading"
        v-if="dataArray.length == 0"
      >
        <div style="margin-top: 300px">
          <div>加载中...</div>
        </div>
      </div>

      <!-- main -->
      <!-- @scroll-to-top="refresh"
      @scroll-to-botton="infinite"-->
      <ScrollLoader
        :minHeight="minHeight"
        class="container-main"
        v-if="dataArray && dataArray.length > 0"
        :style="{ maxHeight: maxHeight - 100 + 'px' }"
        :scrollHeight="scrollHeight"
      >
        <div
          class="message"
          ref="content"
        >
          <ul>
            <li
              v-for="(item, index) in dataArray"
              :key="`${item.msg_id}+${index}`"
              :class="item.direction == 2 ? 'an-move-right' : 'an-move-left'"
            >
              <p class="time">
                <span>{{ item.CreateDate | format }}</span>
              </p>
              <div :class="'main' + (item.Umid ? ' self' : '')">
                <img
                  class="avatar"
                  width="45"
                  height="45"
                  :src="item.Umid? imgUrl2 : imgUrl"
                />
                <!-- 文本 -->
                <div class="text">
                  {{ item.text?item.text:item.Content }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </ScrollLoader>
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/components/headerbar/headerbar";
import ScrollLoader from "./scrollLoader";
import { mapState, mapMutations } from "vuex";
import { getFormat } from "../../lib/units";
import globalVal from "../../store/modules/global";
import { getStore, setStore, removeStore } from "@/lib/storage.js";
export default {
  name: "wxChat",
  components: {
    ScrollLoader,
    HeaderBar,
  },
  props: {
    contactNickname: {
      type: String,
      default: "Mystic Faces",
    },

    // data: {
    //   type: Array,
    //   required: true
    // },

    width: {
      type: Number,
      default: 450,
    },

    wrapBg: {
      type: String,
      default: "#efefef",
    },

    // maxHeight: {
    //   type: Number,
    //   default: 700
    // }

    // contactAvatarUrl: {
    //   type: String
    // },

    // ownerAvatarUrl: {
    //   type: String
    // },

    // getUpperData: {
    //   type: Function,
    //   required: true
    // },

    // getUnderData: {
    //   type: Function,
    //   required: true
    // }
  },

  data() {
    return {
      scrollHeight: 0,
      isUpperLaoding: false,
      isUnderLaoding: false,
      Interval: null,
      isRefreshedAll: false,
      isLoadedAll: false,
      maxHeight: 900,
      minHeight: 700,
      dataArray: [],
    };
  },
  filters: {
    format: function (val) {
      if (val) {
        return getFormat(val);
      }
    },
  },

  inject: ["superParams"],
  watch: {
    "superParams.textnum": {
      immediate: true, // 将立即以表达式的当前值触发回调
      handler: function (val, oldVal) {
        console.log("监听test对象");
        console.log(val, oldVal);
        this.getmorelist();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      msglist: ({ globalVal }) => JSON.parse(getStore("msglist")),
    }),
    imgUrl() {
      return require(`../../assets/driver.png`);
    },
    imgUrl2() {
      return require(`../../assets/shareIcon.png`);
    },
  },
  created() {
    this.initData();
  },
  mounted() {
    const _this = this;
    //document.getElementsByTagName('body')[0].scrollTop=0;
    this.minHeight = document.getElementById(
      "window-view-container"
    ).offsetHeight;
    this.maxHeight = document.getElementById(
      "window-view-container"
    ).offsetHeight;
    this.dataArray = getStore("msglist") ? JSON.parse(getStore("msglist")) : [];
    this.getmorelist();
  },
  updated() {
    this.$nextTick(() => {
      if (this.$refs.content) {
        this.$refs.content.scrollHeight ? this.$refs.content.scrollHeight : 0;
        setStore("scrollHeight", this.$refs.content.scrollHeight);
        this.$refs.content.scrollTop = this.$refs.content.scrollHeight;
        if (this.dataArray.length > 4) {
          this.scrollHeight = this.$refs.content.scrollHeight;
        }
      }
    });
  },
  destroyed() {
    this.dataArray = [];
    clearInterval(this.Interval);
  },
  methods: {
    getmorelist() {
      console.log("wxchat_getmorelist");
      const _this = this;
      let _orderId = getStore("orderId");
      let _dataArray = getStore("msglist")
        ? JSON.parse(getStore("msglist"))
        : [];
      _dataArray.forEach((element, index) => {
        if (element.OrderId != _orderId) {
          _dataArray.splice(index, 1);
        }
      });
      if (_dataArray.length < 3) {
        setStore("scrollHeight", 0);
      } else {
        if (_this.$refs.content && _this.$refs.content.scrollHeight) {
          setStore("scrollHeight", _this.$refs.content.scrollHeight);
        }
      }
      this.dataArray = [..._dataArray];
    },
    goBack() {
      console.log("goBack");
      try {
        this.$router.go(-1);
        console.log("try111");
      } catch (error) {
        this.$router.push("/home");
        console.log("try222");
      }
    },
    initData() {
      let _orderId = getStore("orderId");
      let _dataArray = getStore("msglist")
        ? JSON.parse(getStore("msglist"))
        : [];
      _dataArray.forEach((element, index) => {
        if (element.OrderId != _orderId) {
          _dataArray.splice(index, 1);
        }
      });
      this.dataArray = [..._dataArray];
      console.log("dataarray:", this.dataArray);
    },

    //向上拉刷新
    refresh(done) {
      var me = this;
      if (me.isUpperLaoding) {
        return;
      }

      if (me.isRefreshedAll) {
        done(true);
        me.isUpperLaoding = false;
        return;
      }

      try {
        this.getUpperData().then(function (data) {
          if (data.length == 0) {
            me.isRefreshedAll = true;
            done(true);
          } else {
            me.dataArray = data.reverse().concat(me.dataArray); //倒序合并
            done();
          }
        });
      } catch (error) {
        console.error(
          'wxChat: props "getUpperData" must return a promise object!'
        );
      }
      me.isUpperLaoding = false;
    },

    //向下拉加载
    infinite(done) {
      var me = this;
      if (me.isUnderLaoding) {
        return;
      }
      if (me.isLoadedAll) {
        done(true);
        me.isUnderLaoding = false;
        return;
      }

      try {
        this.getUnderData().then(function (data) {
          if (data == 0) {
            me.isLoadedAll = true;
            done(true);
          } else {
            done();
            me.dataArray = me.dataArray.concat(data); //直接合并
          }
        });
      } catch (error) {
        console.error(
          'wxChat: props "getUnderData" must return a promise object!'
        );
      }
      me.isUnderLaoding = false;
    },
  },
};
</script>


<style scoped>
.wxchat-container {
  width: 100%;
  height: 100%;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
}
.shadow {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.2;
}
.window {
  box-shadow: 1px 1px 20px -5px #000; /*max-width: 450px;*/
  min-width: 300px;
  background: #f5f5f5;
  margin: 0 auto;
  overflow: hidden;
  padding: 0;
  height: 100%;
  position: relative;
  z-index: 101;
}
button {
  border: 0;
  background: none;
  border-radius: 0;
  text-align: center;
}
button {
  outline: none;
}
.w100 {
  width: 100%;
}
.mt5 {
  margin-top: 5px;
}
.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.fs0 {
  font-size: 0;
}
.title {
  background: #000;
  text-align: center;
  color: #fff;
  width: 100%;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
  padding: 10px 0;
  color: rgba(51, 51, 51, 1);
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.title p {
  margin: 0;
}
.title img {
  width: 30px;
  height: 40px;
  float: left;
  padding: 20px;
}
.loading,
.no-more {
  text-align: center;
  color: #b0b0b0;
  line-height: 100px;
}
.no-more {
  line-height: 60px;
}
.pull-right {
  float: right;
}
.link-line {
  text-decoration: underline;
}
.message {
  /*height: 100%;*/
  padding: 10px 15px;
  /*overflow-y: scroll;*/
  background-color: #f5f5f5;
  padding-top: 0;
}
.message li {
  margin-bottom: 15px;
  left: 0;
  position: relative;
  display: block;
}
.message li:nth-child(1) {
  /* margin-top: 100px; */
}
.message ul > li:last-child {
  margin-bottom: 70px;
  color: red;
  font-size: 40px;
}
.message .time {
  margin: 10px 0;
  text-align: center;
}
.message .text {
  display: inline-block;
  position: relative;
  padding: 0 10px;
  max-width: calc(100% - 170px);
  min-height: 35px;
  line-height: 2.1;
  font-size: 30px;
  padding: 6px 10px;
  text-align: left;
  word-break: break-all;
  background-color: #fff;
  color: #000;
  border-radius: 4px;
  box-shadow: 0px 1px 7px -5px #000;
}
.message .avatar {
  float: left;
  margin: 0 10px 0 0;
  border-radius: 3px;
  background: #fff;
}
.message .time > {
  text-align: center;
}
.message .time > span {
  display: inline-block;
  padding: 0 5px;
  font-size: 26px;
  color: #fff;
  border-radius: 2px;
  background-color: #dadada;
}
.message .system > span {
  padding: 4px 9px;
  text-align: left;
}
.message .text:before {
  content: " ";
  position: absolute;
  top: 9px;
  right: 100%;
  border: 6px solid transparent;
  border-right-color: #fff;
}
.message .self {
  text-align: right;
}
.message .self .avatar {
  float: right;
  margin: 0 0 0 10px;
}
.message .self .text {
  background-color: #9eea6a;
}

.message .self .text:before {
  right: inherit;
  left: 100%;
  border-right-color: transparent;
  border-left-color: #9eea6a;
}
.message .image {
  max-width: 200px;
}
img.static-emotion-gif,
img.static-emotion {
  vertical-align: middle !important;
}

.an-move-left {
  left: 0;
  animation: moveLeft 0.7s ease;
  -webkit-animation: moveLeft 0.7s ease;
}
.an-move-right {
  left: 0;
  animation: moveRight 0.7s ease;
  -webkit-animation: moveRight 0.7s ease;
}
.bgnone {
  background: none;
}
@keyframes moveRight {
  0% {
    left: -20px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}

@-webkit-keyframes moveRight {
  0% {
    left: -20px;
    opacity: 0;
  }
  100% {
    left: 0px;
    opacity: 1;
  }
}
@keyframes moveLeft {
  0% {
    left: 20px;
    opacity: 0;
  }
  100% {
    left: 0px;
    opacity: 1;
  }
}

@-webkit-keyframes moveLeft {
  0% {
    left: 20px;
    opacity: 0;
  }
  100% {
    left: 0px;
    opacity: 1;
  }
}

@media (max-width: 367px) {
  .fzDInfo {
    width: 82%;
  }
}
</style>
